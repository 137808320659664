import React, { useState, useEffect } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Header = ({ currentLocation }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [visibilityClass, setVisibilityClass] = useState('');

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setVisibilityClass('navbar-shrink');
    } else {
      setVisibilityClass('');
    }
  };

  const handleClick = () => {
    if (openMenu) {
      setOpenMenu(!openMenu);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
      id="mainNav"
    >
      <div className="container">
        <div className="logo-container">
          <AnchorLink className="navbar-brand" to="/#home" stripHash={true}>
            {visibilityClass.length !== 0 ? (
              <img
                src={'https://assets.gust.io/logo_black.webp'}
                className="img"
                alt="Gust"
              />
            ) : (
              <img
                src={'https://assets.gust.io/logo_white.webp'}
                className="navbar___logo-desktop"
                alt="Gust"
              />
            )}
            {visibilityClass.length === 0 && (
              <img
                src={'https://assets.gust.io/logo_black.webp'}
                className="navbar___logo-mobile"
                alt="Gust"
              />
            )}
          </AnchorLink>
        </div>
        {currentLocation === '/' && (
          <>
            <button
              onClick={toggleMenu}
              onKeyDown={toggleMenu}
              className={`navbar-toggler navbar-toggler-right ${
                openMenu ? '' : 'collapsed'
              }`}
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded={openMenu}
              aria-label="Toggle navigation"
            >
              {openMenu ? (
                <i className="fas fa-times"></i>
              ) : (
                <i className="fas fa-bars" />
              )}
            </button>
            <div
              className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
              id="navbarResponsive"
            >
              <ul
                className={
                  openMenu
                    ? 'navbar-nav navbar-nav-expanded'
                    : 'navbar-nav nav-links'
                }
              >
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink className="nav-link" to="/#home" stripHash={true}>
                    Home
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink
                    className="nav-link"
                    to="/#features"
                    stripHash={true}
                  >
                    Features
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink
                    className="nav-link"
                    to="/#download"
                    stripHash={true}
                  >
                    Download
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink className="nav-link" to="/#faq" stripHash={true}>
                    FAQ
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink className="nav-link" to="/#more" stripHash={true}>
                    Blog
                  </AnchorLink>
                </li>
                <li
                  className="nav-item"
                  onClick={handleClick}
                  onKeyDown={handleClick}
                >
                  <AnchorLink
                    className="nav-link"
                    to="/#contact"
                    stripHash={true}
                  >
                    More
                  </AnchorLink>
                </li>
              </ul>

              {openMenu && <hr />}

              <ul
                className={
                  openMenu
                    ? 'navbar-nav navbar-nav-expanded-adv'
                    : 'navbar-nav nav-link-advertisers'
                }
              >
                <li className="advertisers">
                  <a
                    className="nav-link"
                    href="https://gustprogram.com/get-started/"
                  >
                    Advertisers <i className="fas fa-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
