import React from 'react';
import { Link } from 'gatsby';

const getYear = () => {
  return new Date().getFullYear();
};

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; SDA Media {getYear()}. All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link
              to="https://assets.gust.io/GustSubscriber-PrivacyPolicy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="https://assets.gust.io/GustSubscriber-TC.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
